import { axiosInstance } from "services";
import axios from "axios";
import { firebase } from "@services/firebase";
import { queryObjSerialize } from "@utils/index";
import toastr from "toastr";
import { IMPERSONATION } from "src/constants/localStorageKeys";

export const uploadFile = async (data, type = "company") => {
  try {
    let { signedUrl, path, errors } = await getSignedUrl(data, type);

    if (errors) throw errors[0];
    if (!signedUrl) return;

    const file = new File([data], data.name);
    let result = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": data.type || "image/png",
      },
    });
    if (result?.status === 200)
      return {
        name: data.name,
        size: data.size,
        path,
      };
  } catch (error) {
    console.log(error.response || error);
    throw error.errors ? error.errors[0] || error : error.response || error;
  }
};
export const uploadUserFile = async (data, type = "user/coverPhotos") => {
  try {
    let { signedUrl, path, errors } = await getSignedUrl(data, type);

    if (errors) throw errors[0];
    if (!signedUrl) return;

    const file = new File([data], data.name);
    let result = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": data.type || "image/png",
      },
    });
    if (result?.status === 200)
      return {
        name: data.name,
        size: data.size,
        path,
      };
  } catch (error) {
    console.log(error.response || error);
    throw error.errors ? error.errors[0] || error : error.response || error;
  }
};
export const uploadDocumentFile = async (data, type = "company/documents") => {
  try {
    let { signedUrl, path, errors } = await getSignedUrl(data, type);

    if (errors) throw errors[0];
    if (!signedUrl) return;

    const file = new File([data], data.name);
    let result = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": data.type || "image/png",
      },
    });
    if (result?.status === 200)
      return {
        name: data.name,
        size: data.size,
        path,
      };
  } catch (error) {
    console.log(error.response || error);
    throw error.errors ? error.errors[0] || error : error.response || error;
  }
};

const getSignedUrl = (data, type) => {
  return axiosInstance
    .post("sign-url", {
      fileName: data.name,
      contentType: data.type,
      type: type,
    })
    .then(({ data, status }) => {
      if (status === 200) return data;
      else return {};
    });
};

export const uploadAvatar = ({ ...photo }) => {
  return axiosInstance
    .post("/users/update-avatar", {
      photo,
    })
    .then(({ data, status }) => {
      if (status === 200) return data;
      else return {};
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
};
export const updateUserCover = async (cover) => {
  try {
    if (!cover) return;
    let coverPhoto = await uploadUserFile(cover);
    const { data, status } = await axiosInstance.post(`/users/update-cover`, {
      coverPhoto,
    });
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const listenReloadOfferDetail = (userId, offerId, callback) => {
  let url = `users/${userId}/offers/${offerId}/notifications`;
  let firestore = firebase.firestore;
  let createAt = Date.now();

  const reloadListener = firestore()
    .collection(url)
    .where("createdAt", ">", createAt)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        let typeArrays = [];
        let mergedTypeArrays = [];

        snapshot.docChanges().forEach(function (change) {
          const { type } = change.doc.data();
          typeArrays.push(type);
        });

        // Merge arrays of arrays
        mergedTypeArrays = [].concat.apply([], typeArrays);
        // Remove duplicate
        callback([...new Set(mergedTypeArrays)]);
      }
    });

  return reloadListener;
};

export const listenEvents = (userId, callback) => {
  let url = `users/${userId}/events`;
  let firestore = firebase.firestore;
  let createAt = Date.now();

  const reloadListener = firestore()
    .collection(url)
    .where("createdAt", ">", createAt)
    .onSnapshot((snapshot) => {
      if (snapshot) {
        let typeArrays = [];
        let mergedTypeArrays = [];

        snapshot.docChanges().forEach(function (change) {
          const { type } = change.doc.data() || {};
          typeArrays.push(type);
        });

        // Merge arrays of arrays
        mergedTypeArrays = [].concat.apply([], typeArrays);
        // Remove duplicate
        callback([...new Set(mergedTypeArrays)]);
      }
    });

  return reloadListener;
};

export const calculateProducePrices = async (body) => {
  try {
    const { data, status } = await axiosInstance.post(
      "/calculate-produce-prices",
      body
    );
    if (status === 200) return data;
  } catch (error) {
    console.log(error);
  }
};

export const getListProduceAddress = async (queryParams) => {
  try {
    const { companyId, ...restQueryParams } = queryParams;
    let queryStrings = queryObjSerialize(restQueryParams);
    const result = await axiosInstance.get(
      `/companies/${companyId}/address-produces?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const setTotalUnreadNotify = (userId, number) => {
  let url = `users`;
  let firestore = firebase.firestore;
  let channelRefs = firestore().collection(url).doc(userId);

  return channelRefs
    .update({
      totalUnreadNotify: firestore.FieldValue.increment(number),
    })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
};

export const pageDisplayActionLog = async (actionId, pageDisplay) => {
  try {
    const impersonation = localStorage.getItem(IMPERSONATION);
    if (!impersonation) {
      const { data, status } = await axiosInstance.post("/logs/page-display", {
        actionId,
        subAction: pageDisplay,
      });
    }
    if (status === 200) return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionLog = async (actionId, action) => {
  try {
    const { data, status } = await axiosInstance.post("/logs", {
      actionId,
      action,
    });
    if (status === 200) return data;
  } catch (error) {
    console.log(error);
  }
};

export const getSubscriptionPackages = async () => {
  try {
    const response = await axiosInstance.get(`/packages`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error);
  }
};

export const getZeroToken = async () => {
  try {
    const response = await axiosInstance.get("/xero/getToken");

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error);
    console.error(error);
  }
};

export const sendZeroToken = async (url) => {
  try {
    const response = await axiosInstance.get(`/xero/callback${url}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error);
    console.error(error);
  }
};

export const disconnectXero = async () => {
  try {
    const response = await axiosInstance.get("/xero/disconnect");

    if (response) {
      toastr.success("Disconnected successfully.");
    }
  } catch (error) {
    toastr.error(error.message);
    console.error(error.message);
  }
};

export const getInvoiceCompanyAndProductTypes = async (target) => {
  try {
    const response = await axiosInstance.post("/active-invoices-info", {
      target: target,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSurplusSellersBuyers = async () => {
  try {
    const response = await axiosInstance.post("/admin-surplus-info", {
      type: "offer",
    });
    if (response) return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const ValidateHTML = async (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString("<div><p style=\"margin: 0px; font-style: normal; font-variant-caps: normal; font-stretch: normal; font-size: 12px; line-height: normal; font-family: Helvetica; font-size-adjust: none; font-kerning: auto; font-variant-alternates: normal; font-variant-ligatures: normal; font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-position: normal; font-feature-settings: normal; font-optical-sizing: auto; font-variation-settings: normal; color: rgb(0, 0, 0);\">Calling on our network, we need to act together and support the FPC to make sure border law changes don’t handicap businesses in our industry and lead to higher prices for consumers.</p></div><div><br></div><div><a href=\"https://www.fpcfreshtalkdaily.co.uk/post/new-border-controls-spell-economic-turmoil-the-stark-warning-from-the-fresh-produce-sector-amid-ele\">https://www.fpcfreshtalkdaily.co.uk/post/new-border-controls-spell-economic-turmoil-the-stark-warning-from-the-fresh-produce-sector-amid-ele</a></div>", "text/html");

  const anchorElements = doc.querySelectorAll("div:empty");
  
  if (anchorElements.length) {
    return false;
  } else {
    return true;
  }
};
